<template>
<a class="icon-download-pdf" @click="open_pdf()" data-html2canvas-ignore="true" style="cursor:pointer;" v-if="check_browser && !host.includes('prismheor')"></a>
<a v-if="isAdmin()" :href="modifyPage($route.params.agency, $route.params.id)" target="_blank" rel="noopener"> 
    <span class="icon-pencil" data-html2canvas-ignore="true"></span>
</a>
</template>

<script>
import html2pdf from 'html2pdf.js'
import { isAdmin, modifyPage} from '../../../utils';
export default {
    name : "PDFModifyButton",
    computed : {
        check_browser(){
            return !(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
        },
        drug_id(){
            return this.$store.getters['detail/drug_id'];
        },
        host(){
            return location.hostname
        }
    },
    props:{
        agency: {
            type : String
        }
    },
    methods : {
        isAdmin,
        modifyPage,
        open_pdf(){
            let element = document.body
            let opt = {
                margin: 0,
                filename: this.agency+'-'+this.drug_id+".pdf",
                image: { type: 'png'},
                html2canvas: { dpi: 300, letterRendering: true, imageTimeout : 0, scale:2, backgroundColor : null, windowWidth: 800},
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait'}
            }
            element.style.padding = 0
            document.getElementById('header').style.position = "static"
            // document.getElementById('detail').classList.add('mx-0')

            html2pdf().set(opt).from(element).save().catch(function(err){
                console.error(err)
            })
            
            this.$forceUpdate()
        },
    },
}
</script>

<style>

</style>